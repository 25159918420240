/* CryptoExchange.css */

/* Основной контейнер */
.containerEXCHNAGER {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100vh; /* Изменено на height */
    padding: 10px;
    background-color: #313B43;
    color: #FFFFFF;
    font-family: Arial, sans-serif;
    width: 100%;
    overflow-x: hidden; /* Скрытие горизонтального скролла */
    overflow-y: hidden; /* Скрытие вертикального скролла */
}

*, *::before, *::after {
    box-sizing: border-box;
}



/* Основной блок обмена */
.exchangeBox {
    width: 100%;
    max-width: 400px;
    padding: 20px;
    border-radius: 10px;
    background-color: #313B43;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

/* Заголовок */
.title {
    margin-top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
    margin-bottom: 20px;
    text-align: center;
}

/* Группы ввода и вывода */
/* Увеличение высоты блоков обмена */
.inputGroup,
.outputGroup {
    height: auto; /* Задайте нужную высоту */
    padding: 20px; /* Увеличьте внутренний отступ для больше пространства */
    border-radius: 10px;
    width: 100%;
    background-color: #58626A;
    color: #FFF;
    box-sizing: border-box;
    transition: background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease; /* Плавный переход */
    background-color: #58626A; /* Полупрозрачный фон при наведении */
    border: 1px solid #58626A; /* Цвет границы при наведении */
    box-shadow: 0 0 10px #58626A; /* Тень для выделения */
    margin-top: 20px; /* Добавляем отступ сверху, если нужно */ 
}

/* Метки */
.label {
    font-size: 0.9em;
    color: #959595; /* Укажите нужный цвет, например, оранжевый (#FFA500) */
    text-align: left; /* Выравнивание по левому краю */
    display: block; /* Заставляем метку занимать всю ширину строки */
    margin-bottom: 5px; /* Добавьте отступ снизу для небольшого промежутка */
}

/* Строка ввода */
.inputRow {
    display: flex; /* Используем Flexbox для выравнивания */
    align-items: center; /* Центрируем по вертикали */
    justify-content: space-between; /* Распределяем элементы по краям */
    height: 50%; /* Высота строки */
    font-size: 1.2em;
    padding-top: 5px;
}

/* Эффект фокуса на инпутах */
.inputRow:focus-within .inputCrypto,
.inputRow:focus-within .inputUSDT {
    outline: none; /* Убираем стандартное обрамление */
    box-shadow: 0 0 5px rgba(51, 162, 255, 0.7); /* Тень для выделения инпута */
}

.cryptoLink {
    display: flex; /* Чтобы иконка и текст были на одной линии */
    align-items: center; /* Центрируем по вертикали */
    text-decoration: none; /* Убираем подчеркивание */
    color: inherit; /* Наследуем цвет текста от родителя */
}

.iconImage {
    width: 30px; /* Задайте нужную ширину */
    height: auto; /* Сохраняет пропорции изображения */
    margin-right: 5px; /* Отступ справа от изображения, если нужно */
}


.cryptoInfo {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.inputCrypto,
.inputUSDT {
    width: 80px; /* Ширина поля ввода */
    background-color: transparent; /* Прозрачный фон */
    border: none; /* Убираем границу */
    text-align: right; /* Выравнивание текста справа */
    font-size: 1.1em; /* Размер шрифта */
    color: #FFF; /* Цвет текста */
    padding: 0px; /* Внутренний отступ */
    border-radius: 5px; /* Закругленные углы */
}

.hidden-span {
    visibility: hidden;
    position: absolute;
    white-space: pre;
    font-size: 1.1em; /* Убедитесь, что он совпадает с font-size input */
}

/* Контейнер кнопки */
.buttonContainer {
    width: 100%;
    max-width: 400px;
    padding: 10px 0 45px 0;
    background-color: #313B43;
}

/* Кнопка оплаты */
.button {
    padding: 25px;
    width: 100%;
    background-color: #33A2FF;
    color: #FFF;
    border: none;
    border-radius: 10px;
    font-size: 1.2em;
    cursor: pointer;
    box-sizing: border-box;
}

/* Адаптивные стили */
@media (max-width: 480px) {
    .container {
        padding: 5px;
    }

    .exchangeBox {
        padding: 10px;
    }

    .inputCrypto,
    .inputUSDT {
        font-size: 1em;
        width: 100%;
    }
}

.dividerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px 0;
}

.line {
    height: 2px;
    flex-grow: 1;
    background: #3D474F;
}

.leftLine {
    margin-right: 10px;
}

.rightLine {
    margin-left: 10px;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    background-color: #345659;
    border-radius: 50%;
}

.swapIcon {
    color: #398B85; /* Цвет стрелок */
    font-size: 1.2em; /* Размер символов */
    line-height: 1; /* Убедитесь, что line-height не превышает 1 */
}

/* Стили для изображений иконок */
.iconImage {
    width: 40px; /* Ширина изображения */
    height: 40px; /* Автоматическая высота */
    margin-right: 10px; /* Отступ справа от изображения */
    border-radius: 50px;
}

/* Стили для названий криптовалют */
.cryptoName {
    font-size: 0.9em; /* Размер шрифта */
    color: #FFFFFF; /* Цвет текста */
    margin-left: 0px; /* Отступ слева */
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


.textAreaCustom {
    width: 100%;
    height: 60px;
    background-color: transparent; /* Прозрачный фон */
    border: 1px solid #58626A; /* Граница аналогичная input */
    border-radius: 10px; /* Закругленные углы */
    font-size: 1.1em; /* Размер текста */
    color: #FFF; /* Цвет текста */
    padding: 10px; /* Внутренний отступ */
    box-sizing: border-box; /* Учитываем границы и padding */
    resize: none; /* Отключаем возможность изменения размера */
    outline: none; /* Убираем стандартное выделение */
    transition: background-color 0.3s ease, border-color 0.3s ease; /* Плавный переход */
}

.textAreaCustom:focus {
    border-color: #33A2FF; /* Цвет границы при фокусе */
    box-shadow: 0 0 5px rgba(51, 162, 255, 0.7); /* Подсветка при фокусе */
    background-color: #3D474F; /* Легкое затемнение фона при фокусе */
}


.error-text {
    color: rgb(228, 85, 85);
    font-size: 12px;
    margin-top: 5px;
    display: block;
}


input[type="number"] {
    -moz-appearance: textfield; /* Для Firefox */
}


.back-buttons {
    width: 100%;
    padding: 10px;
    background-color: #475b6b;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: auto;
}

.back-buttons:hover {
    background-color: #45535f;
}
