.main-ref {
    text-align: center;
    background: linear-gradient(rgba(15,106,189,1) 0%, rgba(0,0,0,1) 81%);
    color: white;
    padding: 20px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    padding-bottom: 100px
}


.LogoClass {
    width: 150px;
    height: 88px;
    margin-bottom: 10px;
    filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.8));
}


.profile-image {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    margin: 10px auto;
    outline: 2px solid;
    filter: drop-shadow(5px 8px 4px rgba(0,0,0,0.8));
}

.balance {
    font-size: 2.5rem;
    margin: 20px 0;
}

.btn {
    display: block;
    /* margin: 10px auto;
    padding: 10px 20px; */
    width: 400px;
    height: 60px;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.bt {
    display: block;
    margin: 10px auto;
    padding: 10px 20px;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.referral-btn {
    background-color: #ffffff;
    color: #3a9ee6;
    font-size: 20px;
    font-weight: bold;
    margin-top: auto;
}

.withdraw-btn {
    background-color: #3a9ee6;
    color: #ffffff;
    font-size: 21px;
    font-weight: bold;
}

.btn:hover {
    opacity: 0.8;
}

.profile-text {
    margin-top: -7px;
    font-size: 30px;
}