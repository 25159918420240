/* General styling for the page */
body {
    background-color: #000; /* Dark background */
    color: #fff; /* White text for contrast */
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
}

/* Main container for the cryptocurrency list */
.App {
    max-width: 600px;
    margin: 0 auto;
    padding: 7px;
}

/* Cryptocurrency list */
ul {
    list-style-type: none;
    padding: 0;
}

/* List item styling */
li {
    display: flex;
    align-items: center;
    background-color: #111; /* Darker background for list items */
    padding: 13px;
    margin-bottom: 10px;
    border-radius: 8px;
    transition: background-color 0.3s ease;
    border-bottom: 1px solid #333; /* Adding a bottom border as a divider */
}

li:last-child {
    border-bottom: none; /* Removing the bottom border from the last item */
}

li:hover {
    background-color: #222; /* Slight hover effect */
}

/* Icon styling */
li img {
    margin-right: 15px;
    border-radius: 50%; /* Circular icons */
    background-color: #222; /* Background for image (in case of transparency) */
    width: 50px;
    height: 50px;
}

/* Container for text */
.crypto-info {
    display: flex;
    flex-direction: column; /* Stack title, description, and network vertically */
    align-items: flex-start; /* Align text to the left */
}

/* Text styling */
li h2 {
    margin: 0;
    font-size: 18px;
    color: #00FF7F; /* Light green for titles */
}

/* Description styling */
li p {
    margin: 5px 0 0 0; /* Small space between the title and description */
    color: #bbb; /* Lighter grey for descriptions */
    font-size: 14px;
}

/* Network styling (TON) */
.network {
    margin-top: 5px;
    color: #888; /* Light grey for the network (TON) */
    font-size: 12px;
}

/* Specific overrides for each cryptocurrency (optional customization based on crypto names) */
li h2.Tether {
    color: #00d084; /* Color for Tether */
}

li h2.Toncoin {
    color: #619efb; /* Color for Toncoin */
}

li h2.Solana {
    color: #bb86fc; /* Color for Solana */
}

li h2.Gram {
    color: #ffffff; /* Color for Gram */
}

li h2.Notcoin {
    color: #f5d313; /* Color for Notcoin */
}

li h2.Hamster {
    color: #f5d313; /* Color for Hamster */
}

li h2.Catizen {
    color: #9e4e03; /* Color for Catizen */
}

li h2.MyTonWallet {
    color: #0098EA; /* Color for MyTonWallet */
}

li h2.Dogs {
    color: #ffffff; /* Color for Dogs */
}

li h2.Btc {
    color: #f5d313; /* Color for Hamster */
}

li h2.Dogecoin {
    color: #f5d313; /* Color for Hamster */
}

li h2.Litecoin {
    color: #0098EA; /* Color for Hamster */
}

li h2.Ethereum {
    color: #ffffff; /* Color for Hamster */
}

li h2.Tron {
    color: #ff2600; /* Color for Hamster */
}

/* Header styling */
.Header {
    color: #5e5c5c;
    font-size: large;
    margin-bottom: 10px;
}

.back-button {
    position: absolute;
    top: 35px;
    right: 20px;
    padding: 5px 10px;
    background-color: #ff5757;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    z-index: 1000;
    transition: background-color 0.3s;
}

.back-button:hover {
    background-color: #e64a4a;
}



/* Убираем синее подчеркивание и цвет ссылок */
li a {
    text-decoration: none; /* Убирает подчеркивание */
    color: inherit; /* Наследует цвет текста, заданный для элемента */
    display: flex; /* Сохраняет flex-контейнер, если используется */
    align-items: center;
    width: 100%; /* Заставляет ссылку занимать всю ширину li */
    padding: 13px; /* Обеспечивает пространство вокруг текста и изображений */
}

.search-input {
    width: 90%; /* Ширина поля */
    max-width: 600px; /* Максимальная ширина */
    padding: 10px; /* Внутренние отступы */
    font-size: 16px; /* Размер текста */
    border: 2px solid #363636; /* Серая рамка */
    border-radius: 5px; /* Скругление углов */
    background-color: #000;
    color: #fff; /* Белый текст */
    display: block; /* Поле занимает всю строку */
    box-sizing: border-box; /* Учитывает padding и border в ширине */
    outline: none; /* Убирает обводку при фокусе */
    transition: border-color 0.3s ease; /* Плавный переход при наведении */
}

/* Эффект при наведении */
.search-input:hover {
    border-color: #363636; /* Темнее серой рамки при наведении */
}

/* Эффект при фокусе */
.search-input:focus {
    border-color: #000; /* Зелёная рамка при фокусе */
    background-color: rgb(0, 0, 0); /* Немного затемнённый зелёный */
    color: #fff; /* Белый текст */
}
