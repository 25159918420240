.footer-menu {
    position: static; /* Фиксируем внизу экрана */
    bottom: 0; /* Прижимаем к нижнему краю */
    left: 0;
    width: 100%; /* На всю ширину */
    display: flex;
    justify-content: center; /* Центрируем кнопки */
    gap: 15px; /* Промежуток между кнопками */
    padding: 10px 0;
    z-index: 10;
}

.footer-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px; /* Увеличиваем размер кнопок */
    height: 70px;
    border-radius: 15%; /* Скругление углов */
    background-color: #3a9ee6;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    transition: transform 0.2s, opacity 0.2s;
}

.footer-button:hover {
    transform: scale(1.1); /* Небольшое увеличение при наведении */
    opacity: 0.9;
}

.footer-icon {
    width: 70px; /* Увеличиваем размер иконок */
    height: 70px;
}
