.container {
    background-color: #313B43; /* Задаем фон для контейнера вместо body */
    color: #ffffff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    font-family: 'Arial', sans-serif;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
}

.header {
    margin-bottom: 40px;
}

.rocket-icon {
    width: 250px;
    height: 146px;
    margin-bottom: 10px;
    animation: float 3s infinite ease-in-out;
}

@keyframes float {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}


h1 {
    font-size: 24px;
    margin: 0;
}

p {
    font-size: 14px;
    color: #b0b0b0;
}

.menu {
    width: 100%;
    max-width: 400px;
}

.menu-item {
    text-decoration: none; /* Убирает подчеркивание */
    color: inherit; /* Унаследует цвет от родительского элемента */
    display: flex;
    align-items: center;
    background-color: #3b3c4e;
    border-radius: 8px;
    padding: 15px 20px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: background-color 0.4s ease, box-shadow 0.4s ease; /* Плавный переход */
    outline: none; /* Убирает обводку */
    box-shadow: none; /* Убирает тень */
}

.menu-item:focus {
    outline: none; /* Убирает обводку при фокусе */
}

.menu-item:hover {
    background-color: #4b4c5e; /* Цвет фона при нажатии */
    box-shadow: 0 0 10px rgb(79, 85, 90, 0.5); /* Эффект расплывшегося цвета */
    transform: scale(1.05); /* Увеличение размера на 20% */
}

.icon {
    width: 30px;
    height: 30px;
    margin-right: 15px;
}


.exchange {
    background-color: #3498db;
    border-radius: 50%;
}


.icon.exchange {
    background-color: #3498db; /* Цвет фона, если изображение частично прозрачно или не занимает всю область */
    background-image: url('https://api.just-trade.ru/api/public/images/pic/exchangPic.png');
    background-size: cover; /* Изображение растягивается, чтобы покрыть весь элемент */
    background-position: center; /* Центрирование изображения */
    border-radius: 20%; /* Сохранение закругленных краев */
    width: 30px; /* Ширина элемента */
    height: 30px; /* Высота элемента */
}


.trade {
    background-color: #ecf0f1;
    border-radius: 50%;
}


.icon.trade {
    background-color: #3498db; /* Цвет фона, если изображение частично прозрачно или не занимает всю область */
    background-image: url('https://api.just-trade.ru/api/public/images/pic/exchangexRocketCB.png');
    background-size: cover; /* Изображение растягивается, чтобы покрыть весь элемент */
    background-position: center; /* Центрирование изображения */
    border-radius: 20%; /* Сохранение закругленных краев */
    width: 30px; /* Ширина элемента */
    height: 30px; /* Высота элемента */
}


.icon.tradeCBxRocket {
    background-color: #3498db; /* Цвет фона, если изображение частично прозрачно или не занимает всю область */
    background-image: url('https://api.just-trade.ru/api/public/images/pic/tradeCrypto2.png');
    background-size: cover; /* Изображение растягивается, чтобы покрыть весь элемент */
    background-position: center; /* Центрирование изображения */
    border-radius: 20%; /* Сохранение закругленных краев */
    width: 30px; /* Ширина элемента */
    height: 30px; /* Высота элемента */
}


.icon.tradexRocketCB {
    background-color: #3498db; /* Цвет фона, если изображение частично прозрачно или не занимает всю область */
    background-image: url('https://api.just-trade.ru/api/public/images/pic/tradexRocketCB.png');
    background-size: cover; /* Изображение растягивается, чтобы покрыть весь элемент */
    background-position: center; /* Центрирование изображения */
    border-radius: 20%; /* Сохранение закругленных краев */
    width: 30px; /* Ширина элемента */
    height: 30px; /* Высота элемента */
}


.icon.tradeCBEW {
    background-color: #3498db; /* Цвет фона, если изображение частично прозрачно или не занимает всю область */
    background-image: url('https://api.just-trade.ru/api/public/images/pic/externalWalletToAll.png');
    background-size: cover; /* Изображение растягивается, чтобы покрыть весь элемент */
    background-position: center; /* Центрирование изображения */
    border-radius: 20%; /* Сохранение закругленных краев */
    width: 30px; /* Ширина элемента */
    height: 30px; /* Высота элемента */
}


.icon.tradexRocketEW {
    background-color: #ffffff;
    background-image: url('https://api.just-trade.ru/api/public/images/pic/externalWalletToAll.png');
    background-size: cover; /* Изображение растягивается, чтобы покрыть весь элемент */
    background-position: center; /* Центрирование изображения */
    border-radius: 20%; /* Сохранение закругленных краев */
    width: 30px; /* Ширина элемента */
    height: 30px; /* Высота элемента */
}


.tradeCBxRocket {
    background-color: #5dade2;
    border-radius: 50%;
}

.tradexRocketCB {
    background-color: #f1c40f;
    border-radius: 50%;
}

.menu-item span {
    flex-grow: 1;
    font-size: 18px;
    display: flex;
    align-items: center;
}

.arrow {
    width: 10px;
    height: 10px;
    border-right: 2px solid #fff;
    border-top: 2px solid #fff;
    transform: rotate(45deg);
}