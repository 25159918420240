/* Общий контейнер */
.container-history {
    max-width: 100%;
    margin: 0 auto;
    padding: 15px;
    background-color: #121212; /* Тёмный фон */
    border-radius: 0;
    font-family: Arial, sans-serif;
    color: #e5e5e5;
}

/* Заголовок */
h2 {
    text-align: center;
    color: #f5f5f5;
    margin-bottom: 20px;
    font-size: 22px;
}

/* Заголовок даты */
.date-header {
    font-size: 14px;
    font-weight: bold;
    color: #a5a5a5;
    padding: 5px 10px;
    margin-top: 10px;
}

/* Стили транзакции */
.transaction {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    background-color: #1e1e1e;
    border-radius: 8px;
    margin: 8px 0;
    transition: transform 0.3s ease, background-color 0.3s ease;
}

.transaction:hover {
    background-color: #292929;
    transform: translateY(-2px);
}

/* Иконка транзакции */
.transaction-icon {
    font-size: 20px;
    margin-right: 10px;
    color: #ffcc00; /* Жёлтая иконка */
}

/* Содержимое транзакции */
.transaction-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    font-size: 14px;
}

.transaction-path {
    color: #ffffff;
    font-weight: bold;
}

.transaction-date {
    font-size: 12px;
    color: #aaaaaa;
}

/* Суммы транзакций */
.amounts {
    text-align: right;
    font-size: 14px;
    min-width: 100px;
}

.amounts .positive {
    color: #28a745; /* Зелёный */
    font-weight: bold;
}

.amounts .negative {
    color: #dc3545; /* Красный */
    font-weight: bold;
}

.amounts .small-text {
    font-size: 12px;
    color: #888888;
}

/* Состояние загрузки и конец данных */
p {
    text-align: center;
    color: #aaaaaa;
    font-size: 14px;
    margin: 15px 0;
}

p:last-child {
    font-style: italic;
    color: #888888;
}
